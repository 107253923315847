const brands = {
  vaypol: {
    vaypol: {
      storeName: 'Vaypol',
      storeId: 'vaypol',
      storeDescription:
        'Comprá en nuestra tienda online y recibí tu pedido en tu domicilio o en nuestras sucursales. Envíos gratis a toda la Provincia de Mendoza. ¡Pagá en cuotas sin interés!',
      storeDomain: 'https://www.vaypol.com.ar',
      socialNetworks: [
        'https://www.facebook.com/vaypol/',
        'https://www.instagram.com/vaypol.com.ar/',
        'https://ar.linkedin.com/company/vaypol-casa-de-deportes'
      ],
      storeTitle: 'Tienda Deportiva Online - Calzado e Indumentaria | Vaypol'
    },
    biciva: {
      storeName: 'BiciVa',
      storeId: 'biciva',
      storeDescription:
        'Comprá en nuestra tienda online y recibí tu pedido en tu domicilio o en nuestras sucursales. Envíos gratis a toda la Provincia de Mendoza. ¡Pagá en cuotas sin interés!',
      storeDomain: 'https://www.vaypol.com.ar',
      socialNetworks: [
        'https://www.facebook.com/vaypol/',
        'https://www.instagram.com/vaypol.com.ar/',
        'https://ar.linkedin.com/company/vaypol-casa-de-deportes'
      ],
      storeTitle: 'Tienda Deportiva Online - Calzado e Indumentaria | BiciVa'
    },
    futbolva: {
      storeName: 'FutbolVa',
      storeId: 'futbolva',
      storeDescription:
        'Comprá en nuestra tienda online y recibí tu pedido en tu domicilio o en nuestras sucursales. Envíos gratis a toda la Provincia de Mendoza. ¡Pagá en cuotas sin interés!',
      storeDomain: 'https://www.vaypol.com.ar',
      socialNetworks: [
        'https://www.facebook.com/vaypol/',
        'https://www.instagram.com/vaypol.com.ar/',
        'https://ar.linkedin.com/company/vaypol-casa-de-deportes'
      ],
      storeTitle: 'Tienda Deportiva Online - Calzado e Indumentaria | FutbolVa'
    },
    kidsva: {
      storeName: 'KidsVa',
      storeId: 'kidsva',
      storeDescription:
        'Comprá en nuestra tienda online y recibí tu pedido en tu domicilio o en nuestras sucursales. Envíos gratis a toda la Provincia de Mendoza. ¡Pagá en cuotas sin interés!',
      storeDomain: 'https://www.vaypol.com.ar',
      socialNetworks: [
        'https://www.facebook.com/vaypol/',
        'https://www.instagram.com/vaypol.com.ar/',
        'https://ar.linkedin.com/company/vaypol-casa-de-deportes'
      ],
      storeTitle: 'Tienda Deportiva Online - Calzado e Indumentaria | KidsVa'
    },
    outdoorva: {
      storeName: 'OutdoorVa',
      storeId: 'outdoorva',
      storeDescription:
        'Comprá en nuestra tienda online y recibí tu pedido en tu domicilio o en nuestras sucursales. Envíos gratis a toda la Provincia de Mendoza. ¡Pagá en cuotas sin interés!',
      storeDomain: 'https://www.vaypol.com.ar',
      socialNetworks: [
        'https://www.facebook.com/vaypol/',
        'https://www.instagram.com/vaypol.com.ar/',
        'https://ar.linkedin.com/company/vaypol-casa-de-deportes'
      ],
      storeTitle: 'Tienda Deportiva Online - Calzado e Indumentaria | OutdoorVa'
    },
    runningva: {
      storeName: 'RunningVa',
      storeId: 'runningva',
      storeDescription:
        'Comprá en nuestra tienda online y recibí tu pedido en tu domicilio o en nuestras sucursales. Envíos gratis a toda la Provincia de Mendoza. ¡Pagá en cuotas sin interés!',
      storeDomain: 'https://www.vaypol.com.ar',
      socialNetworks: [
        'https://www.facebook.com/vaypol/',
        'https://www.instagram.com/vaypol.com.ar/',
        'https://ar.linkedin.com/company/vaypol-casa-de-deportes'
      ],
      storeTitle:
        'Todo para el Running: Calzado Técnico y Moda Deportiva | Vaypol'
    },
    tenisva: {
      storeName: 'TenisVa',
      storeId: 'tenisva',
      storeDescription:
        'Comprá en nuestra tienda online y recibí tu pedido en tu domicilio o en nuestras sucursales. Envíos gratis a toda la Provincia de Mendoza. ¡Pagá en cuotas sin interés!',
      storeDomain: 'https://www.vaypol.com.ar',
      socialNetworks: [
        'https://www.facebook.com/vaypol/',
        'https://www.instagram.com/vaypol.com.ar/',
        'https://ar.linkedin.com/company/vaypol-casa-de-deportes'
      ],
      storeTitle: 'Tienda Deportiva Online - Calzado e Indumentaria | TenisVa'
    },
    trainingva: {
      storeName: 'Trainingva',
      storeId: 'trainingva',
      storeDescription:
        'Comprá en nuestra tienda online y recibí tu pedido en tu domicilio o en nuestras sucursales. Envíos gratis a toda la Provincia de Mendoza. ¡Pagá en cuotas sin interés!',
      storeDomain: 'https://www.vaypol.com.ar',
      socialNetworks: [
        'https://www.facebook.com/vaypol/',
        'https://www.instagram.com/vaypol.com.ar/',
        'https://ar.linkedin.com/company/vaypol-casa-de-deportes'
      ],
      storeTitle:
        'Tienda Deportiva Online - Calzado e Indumentaria | TrainingVa'
    },
    urbanva: {
      storeName: 'UrbanVa',
      storeId: 'urbanva',
      storeDescription:
        'Comprá en nuestra tienda online y recibí tu pedido en tu domicilio o en nuestras sucursales. Envíos gratis a toda la Provincia de Mendoza. ¡Pagá en cuotas sin interés!',
      storeDomain: 'https://www.vaypol.com.ar',
      socialNetworks: [
        'https://www.facebook.com/vaypol/',
        'https://www.instagram.com/vaypol.com.ar/',
        'https://ar.linkedin.com/company/vaypol-casa-de-deportes'
      ],
      storeTitle: 'Tienda Deportiva Online - Calzado e Indumentaria | UrbanVa'
    },
    '+sports': { storeId: '+sports' }
  },
  city: {
    city: {
      storeName: 'City',
      storeId: 'city',
      storeDescription:
        'Descubre nuestra amplia gama de productos deportivos que te ayudarán a alcanzar tus metas y disfrutar al máximo de tu pasión por el deporte.',
      storeDomain: 'https://www.somoscity.com.ar',
      socialNetworks: [
        'https://www.facebook.com/citysneaker',
        'https://www.instagram.com/citysneakers.ar/',
        'https://ar.linkedin.com/company/vaypol-casa-de-deportes'
      ],
      storeTitle: 'City | Sneakers'
    }
  }
};
// const storeId = process.env.NEXT_PUBLIC_STORE_ID;

const brand = (storeId) => {
  return brands[process.env.NEXT_PUBLIC_STORE_ID][storeId];
};
export { brands };
export default brand;
